.menuItem {
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.linkItem {
    font-size: 16px;
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.linkItem:hover {
    color: #E37E31;
}

.menuItem:hover {
    color: #E37E31;
}

.menuIcon {
    font-size: 22px;
}