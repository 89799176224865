.label {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    padding: 10px 0;
}

.data {
    font-size: 12px;
    font-weight: 400;
    text-align: right;
    padding: 10px 0;
}