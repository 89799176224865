.spaceBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.justifyCenter {
    display: flex;
    justify-content: center;
}

.spaceAround {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.alignCenter {
    display: flex;
    align-items: center;
}

.columnCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.centerCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}