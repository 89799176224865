.swiper-wrapper {
    height: 100% !important;
}

.no-scrollbar {
    overflow-y: scroll;
    scrollbar-width: 0px; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}