.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip {
    position: absolute;
    top: calc(-150% - 50px); /* Измените на -150% для увеличения на 1.5 раза */
    left: 50%; /* Добавьте left: 50% для центрирования тултипа */
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 10px;
    width: 300px; /* Измените на 1.5 раза больше текущей ширины (200px) */
    height: 150px; /* Измените на 1.5 раза больше текущей высоты (100px) */
    padding: 20px 20px;
    border-radius: 24px;
    z-index: 10000;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.tooltip-container.visible .tooltip {
    opacity: 1;
    transform: translateY(0);
}